import React, { Component } from 'react';

export default class MarketDataWidget extends Component {
    componentDidMount() {
        if (document.getElementById("MarketDataChart")) {
            const script = document.createElement('script');

            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js'
            script.async = true;
            script.innerHTML = JSON.stringify(
                {
                    "width": "100%",
                    "height": 340,
                    "symbolsGroups": [
                        {
                            "name": "Indices",
                            "originalName": "Indices",
                            "symbols": [
                                {
                                    "name": "FOREXCOM:SPXUSD",
                                    "displayName": "S&P 500"
                                },
                                {
                                    "name": "FOREXCOM:NSXUSD",
                                    "displayName": "Nasdaq 100"
                                },
                                {
                                    "name": "FOREXCOM:DJI",
                                    "displayName": "Dow 30"
                                },
                                {
                                    "name": "INDEX:DEU30",
                                    "displayName": "DAX Index"
                                },
                                {
                                    "name": "FOREXCOM:UKXGBP",
                                    "displayName": "FTSE 100"
                                },
                                {
                                    "name": "KRX:KOSPI",
                                    "displayName": "KOSPI"
                                },
                                {
                                    "name": "INDEX:NKY",
                                    "displayName": "Nikkei 225"
                                }
                            ]
                        },
                        {
                            "name": "Commodities",
                            "originalName": "Commodities",
                            "symbols": [
                                {
                                    "name": "CME_MINI:ES1!",
                                    "displayName": "S&P 500"
                                },
                                {
                                    "name": "CME:6E1!",
                                    "displayName": "Euro"
                                },
                                {
                                    "name": "COMEX:GC1!",
                                    "displayName": "Gold"
                                },
                                {
                                    "name": "NYMEX:CL1!",
                                    "displayName": "Crude Oil"
                                },
                                {
                                    "name": "NYMEX:NG1!",
                                    "displayName": "Natural Gas"
                                },
                                {
                                    "name": "CBOT:ZC1!",
                                    "displayName": "Corn"
                                }
                            ]
                        },
                        {
                            "name": "Forex",
                            "originalName": "Forex",
                            "symbols": [
                                {
                                    "name": "FX:EURUSD"
                                },
                                {
                                    "name": "FX:GBPUSD"
                                },
                                {
                                    "name": "FX:USDCHF"
                                },
                                {
                                    "name": "FX:AUDUSD"
                                },
                                {
                                    "name": "FX:USDCAD"
                                },
                                {
                                    "name": "FX_IDC:USDKRW"
                                },
                                {
                                    "name": "FX:USDJPY"
                                },
                            ]
                        }
                    ],
                    "colorTheme": "light",
                    "isTransparent": false,
                    "locale": "en",
                    "autoSize":true
                }
            )

          // script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js'
          // script.async = false;
          // script.innerHTML = JSON.stringify({
          //     "container_id": "tv-medium-widget",
          //     "symbols": [
          //         [
          //         "Bitcoin",
          //         "BITMEX:XBTUSD|1m"
          //         ],
          //         [
          //         "Ethereum",
          //         "BITMEX:ETHUSD|1m"
          //         ]
          //     ],
          //     "greyText": "Quotes by",
          //     "gridLineColor": "rgba(182, 182, 182, 0.65)",
          //     "fontColor": "rgba(0, 0, 0, 1)",
          //     "underLineColor": "rgba(60, 120, 216, 1)",
          //     "trendLineColor": "rgba(60, 120, 216, 1)",
          //     "width": "100%",
          //     "height": "100%",
          //     "locale": "en"
          // })

            document.getElementById("MarketDataChart").appendChild(script);
        }
    }

    render() {
        return (
            <div className="ticker-container" style={{width: "100%", overflow: "hidden"}}>
                <div id="MarketDataChart" />
            </div>
        )
    }
}