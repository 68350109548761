import React from 'react';
import { Row, Col, Jumbotron, Container } from 'reactstrap';
import MobileStoreButton from 'react-mobile-store-button';
import PropTypes from 'prop-types';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import TechnicalAnalysis from './TechAnalysis'
import TickerTapeWidget from './TickerTapeWidget'
import MarketDataWidget from './MarketDataWidget'
import MarketOverviewWidget from './MarketOverviewWidget'
import { Helmet } from 'react-helmet'

const iOSUrl = 'https://itunes.apple.com/us/app/aixbit/id1443449935?mt=8';
const aOSUrl = 'http://play.google.com/store/apps/details?id=com.aixbit.android';

class Home extends React.Component {
  static propTypes = {
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.tvChart = React.createRef()
    this.state = {
      isMobile: false
    };
  }
  
  componentDidMount() {
    // document.title = 'AIXBIT - Prediction price of Stock Indexes and Bitcoin using Machine Learning'

    window.addEventListener('resize', () => {
      this.setState({
          isMobile: window.innerWidth < 1200
      });
    }, false);
  }

  render() {
    const className = this.state.isMobile ? 'mobile' : '';
    return (    
      <div className={className}>
        <Helmet>
          <meta charSet="UTF-8" />
          <title>BullJang - Prediction price of Stock Indexes and Bitcoin using Machine Learning</title>
          <meta name="description" content="Stock Indexes (Dow Jones Index, NASDAQ, S&P 500, RUSSELL 2000) and BITCOIN Price Forecasting" />
          <script type="application/ld+json">{`
          {
            "@context": "https://schema.org/",
            "@type": "MobileApplication",
            "applicationCategory": "Finance",
            "applicationSubCategory": "Stock Indexes, Bitcoin"
            "name": "BullJang, 불장, AIXBIT",
            "operatingSystem": "ANDROID, iOS",
            "supportingData": {
              "@type": "DataFeed",
              "dataFeedElement": [
                {
                  "@type": "DataFeedItem",
                  "name": "Dow Jones Index",
                  "keyword": "Dow Jones Index, DJIA, ^DJI",
                  "sameAs": "https://en.wikipedia.org/wiki/Dow_Jones_Industrial_Average"
                },
                {
                  "@type": "DataFeedItem",
                  "name": "NASDAQ",
                  "keyword": "NASDAQ Composite, ^IXIC",
                  "sameAs": "https://en.wikipedia.org/wiki/NASDAQ_Composite"
                },
                {
                  "@type": "DataFeedItem",
                  "name": "S&P 500",
                  "keyword": "S&P 500, ^GSPC",
                  "sameAs": "https://en.wikipedia.org/wiki/S%26P_500_Index"
                },
                {
                  "@type": "DataFeedItem",
                  "name": "RUSSELL 2000",
                  "keyword": "RUSSELL 2000, ^RUT",
                  "sameAs": "https://en.wikipedia.org/wiki/Russell_2000_Index"
                },
                {
                  "@type": "DataFeedItem",
                  "name": "BITCOIN",
                  "keyword": "BITCOIN, CryptoCurrency, BTC",
                  "sameAs": "https://en.wikipedia.org/wiki/Bitcoin"
                }
              ]
            }
          }
          `}</script>
        </Helmet>
        <Row>
          <Jumbotron className="bg-primary text-white">
            <h1>
              AIXBIT
            </h1>
            <p className="lead">
              Stock Indexes and Bitcoin Price Prediction Using Machine Learning
            </p>
            <p>
              The combination of machine learning, deep learning, natural language processing, and cognitive computing will change the ways that humans and machines interact. AI-driven smart services will sense one's surroundings, learn one's preferences are from past behavior, and subtly guide people and machines through their daily lives in ways that will truly feel frictionless. This quest to deliver AI-driven smart services across all industries and business processes will usher the most significant shift in computing and business this decade and beyond.
            </p>
          </Jumbotron>
        </Row>
        {/* <Row style={{height:"800px"}}>
          <div id="chart" style={{}}> </div>
        </Row> */}
        <Row>
          <TickerTapeWidget />
        </Row>
        <Row style={{height:"700px"}}>
          <MarketOverviewWidget />
        </Row>
        <Row style={{height:"340px"}}>
          <MarketDataWidget />
        </Row>
        {/* <Row style={{height:"300px"}}>
          <TradingViewWidget
            symbol="BITMEX:XBTUSD"
            theme={Themes.Light}
            interval="60"
            locale="en"
            autosize
          />
        </Row> */}
        {/* <Row style={{ marginTop:30, backgroundColor:'#fff' }}>
          <Col>
            <TechnicalAnalysis />
          </Col>
        </Row> */}
        <hr />
        <Row className="pt-5">
          <Col xs="12" md="4" className="pt-3 pt-md-0">
            <h3 style={{marginBottom:30}}>
              iOS
            </h3>
            <MobileStoreButton
              store="ios"
              url={iOSUrl}
              height={50}
              linkProps={{ title: 'iOS Store Button' }}
            />
          </Col>
          <Col xs="12" md="4" className="pt-3 pt-md-0">
            <h3>
              Android
            </h3>
            <MobileStoreButton
              store="android"
              url={aOSUrl}
              linkProps={{ title: 'Android Store Button' }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Home;
