import React, { Component } from 'react';

export default class MarketDataWidget extends Component {
    componentDidMount() {
        if (document.getElementById("TickerTapeChart")) {
            const script = document.createElement('script');
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js'
            script.async = true;
            script.innerHTML = JSON.stringify({
                "symbols": [
                    {
                        "description": "Dow 30",
                        "proName": "FOREXCOM:DJI"
                    },
                    {
                        "description": "Nas 100",
                        "proName": "FOREXCOM:NSXUSD"
                    },
                    {
                        "description": "S&P 500",
                        "proName": "FOREXCOM:SPXUSD"
                    },
                    {
                        "description": "RUT 2000",
                        "proName": "OANDA:US2000USD"
                    },
                    {
                        "proName": "FX_IDC:EURUSD",
                        "title": "EUR/USD"
                    },
                    {
                        "proName": "BITSTAMP:BTCUSD",
                        "title": "BTC/USD"
                    },
                ],
                "colorTheme": "light",
                "isTransparent": false,
                "displayMode": "adaptive",
                "autoSize": true,
                "locale": "en"
            });

            document.getElementById("TickerTapeChart").appendChild(script);
        }
    }

    render() {
        return (
            <div className="ticker-container" style={{ width: "100%", overflow: "hidden" }}>
                <div id="TickerTapeChart" />
            </div>
        )
    }
}