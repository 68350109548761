import React from 'react';
import { Row, Col } from 'reactstrap';

const Footer = () => (
  <footer className="mt-5">
    <Row>
      <Col>
        <p>
          AIXBIT will not accept any liability for any trading loss or damage as a result of reliance on the information contained within this application including data, quotes, charts and buy/sell signals. Please be fully informed regarding the risks and costs associated with trading the financial markets, it is one of the riskiest investment forms possible. AIXBIT doesn't bear any responsibility for any trading losses you might incur as a result of using this data. None of this service should be treated as investment advice. Whichever investments you pursue are purely at your own discretion.
        </p>
      </Col>
      <Col sm="12" className="text-right pt-3">
        <p>
          API<br />
          <a target="_blank" href="/PrivacyPolicy.html">Privacy Policy</a><br />
          <a target="_blank" href="/Terms.html">Terms of Use</a><br />
          {' '}
          {' '}
          <a target="_blank" rel="noopener noreferrer" href="mailto:support@aixbit.com">
            support@aixbit.com
          </a>
        </p>
      </Col>
    </Row>
  </footer>
);

export default Footer;
