export default {
    agent: [
      {
        placeholder: true,
        id: 0,
        name: '---- ---',
        returns: 1,
        cagr: 2,
        mdd: 3,
        start_date: '__ __ __',
        last_date: '__ __ __',
        history_update: '__ __ __',
        version: '_ _ _'
      },
    ],
  };
  