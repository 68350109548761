/* global window */
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/es/storage'; // default: localStorage if web, AsyncStorage if react-native
import AsyncStorage from '@react-native-community/async-storage';
import thunk from 'redux-thunk';
import reducers from '../reducers';
import { loadTranslations, setLocale, syncTranslationWithStore, i18nReducer } from 'react-redux-i18n';
import { Lang } from '../lang/index.js';

// Redux Persist config
const config = {
  key: 'root',
  storage: AsyncStorage,
  blacklist: ['status'],
};

const combineReducer = combineReducers({ ...reducers, i18n: i18nReducer });
const reducer = persistReducer(config, combineReducer);

const middleware = [thunk];

const configureStore = () => {
  const store = createStore(
    reducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    compose(applyMiddleware(...middleware)),
  );

  const persistor = persistStore(
    store,
    null,
    () => { 
      store.getState();
      const translation = loadTranslations(Lang);
      syncTranslationWithStore(store);
      store.dispatch(translation);
      store.dispatch(setLocale('ko')); 
    },
  );

  return { persistor, store };
};

export default configureStore;
