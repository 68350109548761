import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	Row,
	Col,
	Table,
	Badge
} from 'reactstrap';
import Config from '../../constants/config';
import firebase from 'firebase'
import classnames from 'classnames'
import moment from 'moment';
import { MdTrendingDown, MdTrendingUp, MdTrendingFlat } from "react-icons/md"
import { SegmentedControl } from 'segmented-control-react';

const segments = [
	{ name: '5 Min' },
	{ name: '1 Hour' },
	{ name: '1 Day'}
];

class TechnicalAnalysis extends Component {
	static propTypes = {
	}

	static defaultProps = {
	}

	constructor(props) {
		super(props);

		this.state = { 
			segments: segments,
			selected: 0,
			timestamp_loading: true,
			timestamp: 0,
			ta_loading: true,
			ta_results: []
		}
	}

	componentDidMount() {
		const db = firebase.firestore()
		this.ref = db.collection('predict').doc('v1').collection('ta').doc('5m')
		this.ta_ref = this.ref.collection('last');
		this._updateTAList()
	}

	_updateTAList() {
		this.ref_unsubscribe = this.ref.onSnapshot(this.onDocumentTAListUpdate)
		this.ta_ref_unsubscribe = this.ta_ref.orderBy('hot', 'desc').onSnapshot(this.onCollectionTAListUpdate)
	}

	onCollectionTAListUpdate = (querySnapshot) => {
		console.log('onCollectionTAListUpdate', querySnapshot);

		if (0 == querySnapshot.size) {
			this.setState({ ta_results: [] });
			return;
		}

		const ta_results = querySnapshot.docs.map((docSnapshot) => (
			docSnapshot.data()
		));

		console.log('ta_results', ta_results)
		this.setState({
			ta_loading: false,
			ta_results
		});
	}

	onDocumentTAListUpdate = (docSnapshot) => {
		console.log('onDocumentTAListUpdate', docSnapshot)
		const data = docSnapshot.data()
		console.log(docSnapshot.id, " => ", data);
		this.setState({
			refresh: true,
			timestamp_loading: false,
			timestamp: data.last_timestamp.toDate()
		})
	}

	_unloadData() {
		this.ref_unsubscribe()
		this.ta_ref_unsubscribe()

		this.setState({
			selected: 0,
			timestamp_loading: true,
			timestamp: 0,
			ta_loading: true,
			ta_results: []
		})
	}

	componentWillUnmount() {
		this._unloadData()
	}

	render() {
		if (this.state.timestamp_loading || this.state.ta_loading)
			return null

		console.log('tiemstamp', this.state.timestamp)
		const timestamp = moment(this.state.timestamp).format('lll')
		console.log('timestamp format', timestamp)

		return (
			<div>
				<Row style={{marginTop:10}}>
					<Col style={{color:'#000'}}>Technical Analysis</Col>
					<Col style={{fontSize:10, textAlign:'right', alignSelf:'flex-end'}}>{timestamp}</Col>
				</Row>
				<hr />
				<Row style={{marginLeft:10, marginRight:10, marginBottom:-1}}>
					<SegmentedControl
						segments={this.state.segments}
						selected={this.state.selected}
						variant="base"
						onChangeSegment={this.handleChange}
					/>
				</Row>
				<Row style={{ marginLeft:10, marginRight:10}}>
					<Table>
						<tbody>
							{this._listTAResults(this.state.ta_results)}
						</tbody>
					</Table>
				</Row>
			</div>
		);
	}

	handleChange = index => {
		const intervals = ['5m', '1h', '1d']
		if (this.state.selected === index || intervals.length <= index)
			return

		this.ref_unsubscribe()
		this.ta_ref_unsubscribe()

		var db = firebase.firestore();
		this.ref = db.collection('predict').doc('v1').collection('ta').doc(intervals[index])
		this.ta_ref = this.ref.collection('last');

		this._updateTAList()

		this.setState({
			...this.state,
			selected: index
		});
	};

	_listTAResults(results) {
		console.log('results', results)
		const badge = (item) => {
			if (item.hot) {
				return (
					<Badge color='danger' style={{ height: 15, alignSelf: 'center'}} pill>
						<MdTrendingUp style={{ fontSize: 15, color: "#fff", paddingBottom:3}} />
					</Badge>
				)
			} else if (item.cold) {
				return (
					<Badge color='info' style={{ height: 15 }} pill>
						<MdTrendingDown style={{ fontSize: 15, color: "#fff", paddingBottom:3}} />
					</Badge>
				)
			} else {
				return (
					<Badge color='info' style={{ height: 15, backgroundColor: '#999', paddingLeft: 8, paddingRight: 8 }} pill>
						<MdTrendingFlat style={{ fontSize: 15, color: "#fff", paddingBottom:3}} />
					</Badge>
				)
			}
		}

		const items = results.map(item => {
			if (item.group === 'informants')
				return null

			const nameStyle = item.group === 'crossovers' ? {fontSize:12, color:'#000'} : {fontSize:12}

			return (
				<tr key={`${item.type}${item.index}`}>
					<td>
						<Col>
							<Row style={nameStyle}>
								{item.name}
							</Row>
							<Row style={{fontSize:10}}>
								{item.value}
							</Row>
						</Col>
					</td>
					<td>
						<Col style={{textAlign:'center', marginTop:5}}>
							{badge(item)}
						</Col>
					</td>
				</tr>
			)
		})
			console.log('items', items)
		return items
	}
}

export default TechnicalAnalysis;
